import type { Ref } from 'vue';
import { computed } from 'vue';

import { IconDirection, IconName, IconSize } from '@leon-hub/icons';

import type { TwoStateIndicatorConfig } from 'web/src/components/_shared';

export interface LeaderBoardSpoilerComposable {
  indicatorProperties: Ref<TwoStateIndicatorConfig>;
}

export default function useLeaderBoardSpoiler(): LeaderBoardSpoilerComposable {
  const indicatorProperties = computed<TwoStateIndicatorConfig>(() => [{
    direction: IconDirection.SOUTH,
    size: IconSize.SIZE_16,
    name: IconName.CHEVRON,
  }, {
    direction: IconDirection.NORTH,
    size: IconSize.SIZE_16,
    name: IconName.CHEVRON,
  }]);
  return {
    indicatorProperties,
  };
}
