<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { BadgeKind, VBadge } from '@components/badge';
import { ButtonKind, VButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import { VForm } from 'web/src/components/Form';
import { VLoaderDelayed } from 'web/src/components/Loader';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import LeaderBoard from 'web/src/modules/promotions/components/LeaderBoard/LeaderBoard.vue';
import LeaderBoardSpoiler from 'web/src/modules/promotions/components/LeaderBoardSpoiler/LeaderBoardSpoiler.vue';
import LeaderBoardWrapper from 'web/src/modules/promotions/components/LeaderBoardWrapper/LeaderBoardWrapper.vue';
import PromotionDetailsButton from 'web/src/modules/promotions/components/PromotionButton/PromotionButton.vue';
import PromotionCashbackHistory
  from 'web/src/modules/promotions/components/PromotionCashbackHistory/PromotionCashbackHistory.vue';
import PromotionCountdown from 'web/src/modules/promotions/components/PromotionCountdown/PromotionCountdown.vue';
import PromotionDetailsHeader
  from 'web/src/modules/promotions/components/PromotionDetailsHeader/PromotionDetailsHeader.vue';
import PromotionDetailsHowDoesItWork from 'web/src/modules/promotions/components/PromotionDetailsHowDoesItWork';
import PromotionDetailsHowToReceiveBonus from 'web/src/modules/promotions/components/PromotionDetailsHowToReceiveBonus';
import PromotionDetailsSocials
  from 'web/src/modules/promotions/components/PromotionDetailsSocials/PromotionDetailsSocials.vue';
import PromotionRate from 'web/src/modules/promotions/components/PromotionRate/PromotionRate.vue';
import PromotionDetailsRefuseButton
  from 'web/src/modules/promotions/components/PromotionRefuseButton/PromotionsRefuseButton.vue';
import PromotionDetailsTerms from 'web/src/modules/promotions/components/PromotionTerms/PromotionTerms.vue';

import { usePromotionDetailsRoutePage } from './composables';

const {
  isReady,
  currentPromotion,
  currentPromoLeaderboardDate,
  showBadges,
  hasCounter,
  isBeforeStart,
  counterTimestamp,
  isCustomerParticipatingCashback,
  buttonProperties,
  isArchived,
  isDoingActionButton,
  contentLoaded,
  isShowTermsData,
  termsData,
  rounds,
  isLeaderBoard,
  leaderBoardProperties,
  isPromotionsLeaderboardArchiveEnabled,
  spoilerRecord,
  isShowPromoSocialLinks,
  isNicknameModal,
  modalSelector,
  modalProps,
  isNicknameFlowFinal,
  schema,
  uiSchema,
  customErrors,
  terms,
  cashbackAmount,
  isCashbackInfoModal,
  modalCashbackInfoProps,
  board,
  sendRequest,
  preDoButtonAction,
  scrollToLeaderBoard,
  doButtonAction,
  goToLoginPage,
  scrollToTerms,
  goToCustomLink,
  goToPlay,
  refuseCashback,
  loadMore,
  onSpoilerOpen,
  tournamentProperties,
  onClose,
  onLater,
  onInput,
  onSubmit,
  onButtonClick,
  beforeMount,
  beforeUnmount,
  showIdentificationNeededModal,
  doDeposit,
} = usePromotionDetailsRoutePage();

onBeforeMount(beforeMount);
onBeforeUnmount(beforeUnmount);
</script>

<template>
  <div v-auto-id="'PromotionDetailsRoutePage'" :class="$style['component-wrapper']">
    <div :class="$style['promotion']">
      <template v-if="isReady && currentPromotion">
        <article :class="$style['promotion__article']">
          <div
            :class="{
              [$style['promotion__header-wrapper']]: true,
              [$style['promotion__header-wrapper--ended']]: isArchived,
            }"
          >
            <div :class="$style['promotion__image-wrapper']">
              <img
                :class="$style['promotion__image']"
                :src="currentPromotion.bannerUrl || undefined"
                :alt="currentPromotion.shortDescription || undefined"
              >
            </div>
            <div
              v-if="showBadges"
              :class="$style['promotion__image-badges']"
            >
              <VIcon
                v-if="currentPromotion.isHot"
                :size="IconSize.SIZE_16"
                :name="IconName.FIRE"
                :class="$style['promotion__image-hot']"
              />
              <VBadge
                v-if="currentPromotion.isNew"
                :class="$style['promotion__image-badge']"
                :kind="BadgeKind.WHITE"
                label="New"
              />
            </div>
            <VBadge
              v-if="currentPromotion.isNT"
              :class="$style['promotion__image-badge--dev-ip']"
              :kind="BadgeKind.WHITE"
              label="DevIP"
            />
            <div :class="$style['promotion__rate']">
              <PromotionRate v-if="!isArchived" />
            </div>
          </div>

          <div :class="$style['promotion__padded-content']">
            <div :class="$style['promotion__brief-content']">
              <PromotionDetailsHeader
                :class="$style['promotion__title-wrapper']"
                :promotion="currentPromotion"
              />
              <PromotionCountdown
                v-if="hasCounter"
                :is-before-start="isBeforeStart"
                :timestamp="counterTimestamp"
                :is-cashback="isCustomerParticipatingCashback"
                :is-alt-time-name="isCustomerParticipatingCashback"
                @send="sendRequest"
              />
              <PromotionDetailsButton
                v-if="buttonProperties && !isArchived"
                v-bind="buttonProperties"
                :button-is-loading="isDoingActionButton"
                :class="$style['promotion__button']"
                @participate="preDoButtonAction"
                @leaderboard="scrollToLeaderBoard"
                @exchange="doButtonAction"
                @login="goToLoginPage"
                @on-bonus-wallet="scrollToTerms"
                @custom="goToCustomLink"
                @play="goToPlay"
                @identification-needed="showIdentificationNeededModal"
                @deposit="doDeposit"
              />
              <VButton
                v-if="isCustomerParticipatingCashback"
                :label="$t('WEB2_CASHBACK_BUTTON_REFUSE_LABEL')"
                :kind="ButtonKind.SECONDARY"
                :class="$style['promotion__button-refuse']"
                @click="refuseCashback"
              />

              <PromotionDetailsRefuseButton :class="$style['promotion__refuse']" />
            </div>

            <VLoaderDelayed v-if="!contentLoaded" />

            <PromotionDetailsTerms
              v-if="isShowTermsData && termsData?.sportBonus"
              v-bind="termsData"
              ref="terms"
              :class="$style['promotion__term-steps']"
            />

            <PromotionDetailsHowDoesItWork
              :class="$style['promotion__how-does-it-work']"
              :title="currentPromotion.howWorksTitle || ''"
              :items="currentPromotion.howWorksItems || []"
            />

            <PromotionDetailsHowToReceiveBonus
              :class="$style['promotion__how-to-receive-bonus']"
              :title="currentPromotion.bonusTitle || ''"
              :items="currentPromotion.bonusItems || []"
            />
          </div>
          <PromotionCashbackHistory
            v-if="isCustomerParticipatingCashback && !!rounds"
            :list="rounds"
            :current-cashback="cashbackAmount"
          />
          <LeaderBoardWrapper
            v-if="isLeaderBoard"
            ref="board"
            :subheader="currentPromoLeaderboardDate"
          >
            <LeaderBoard
              v-bind="leaderBoardProperties"
              class="promotion__leader-board promotion__leader-board--default"
              @load-more="loadMore"
            />
            <template v-if="isPromotionsLeaderboardArchiveEnabled">
              <LeaderBoardSpoiler
                v-for="tournament in currentPromotion.tournaments"
                :key="tournament.id"
                :class="{
                  [$style['promotion__leader-board']]: true,
                  [$style['promotion__leader-board--spoiler']]: true,
                }"
                :opened="!!spoilerRecord[tournament.id]"
                :title="tournament.name"
                @open="onSpoilerOpen(tournament.id)"
              >
                <LeaderBoard
                  v-bind="tournamentProperties(tournament.id).value"
                  @load-more="loadMore(tournament.id)"
                />
              </LeaderBoardSpoiler>
            </template>
          </LeaderBoardWrapper>
          <PromotionDetailsSocials
            v-if="isShowPromoSocialLinks"
            :class="$style['promotion__socials']"
            :promotion="currentPromotion"
          />
        </article>
      </template>
      <div
        v-if="!isReady"
        :class="$style['loader-masked']"
      >
        <VLoaderDelayed />
      </div>
    </div>
    <Teleport
      v-if="isNicknameModal"
      :to="modalSelector"
    >
      <ConfirmModal
        v-bind="modalProps"
        :is-alert="!!'1'"
        @close="onClose"
        @button-click="onLater"
      >
        <VForm
          v-if="!isNicknameFlowFinal"
          :schema="schema"
          :ui-schema="uiSchema"
          :custom-errors="customErrors"
          is-from-modal
          :class="$style['modal__form']"
          @input="onInput"
          @submit="onSubmit"
        />
      </ConfirmModal>
    </Teleport>
    <Teleport
      v-if="isCashbackInfoModal"
      :to="modalSelector"
    >
      <ConfirmModal
        :modal="modalCashbackInfoProps"
        :is-alert="!!'1'"
        @close="onClose"
        @button-click="onButtonClick"
      />
    </Teleport>
  </div>
</template>

<style lang="scss" module>
@import '../../styles/promotion';
</style>
