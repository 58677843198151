<script setup lang="ts">
import { computed, onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { Tag } from '@leon-hub/tags';

import { VButton } from '@components/buttons';

import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VHowToHintBlock from 'web/src/components/HowToHintBlock/VHowToHintBlock/VHowToHintBlock.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import PromotionDetailsDescription
  from 'web/src/modules/promotions/components/PromotionDetailsDescription/PromotionDetailsDescription.vue';
import PromotionDetailsHowToReceiveBonus from 'web/src/modules/promotions/components/PromotionDetailsHowToReceiveBonus';
import { usePromotionsStore } from 'web/src/modules/promotions/store';

const promotionsStore = usePromotionsStore();
const router = useRouter();

const bonusWallet = toRef(promotionsStore, 'bonusWallet');
const isReady = computed(() => bonusWallet.value?.actionUrl !== undefined);

const titleTag = computed(() => ('1' ? Tag.H1 : Tag.H2));

function onBonusWalletButtonClick(): void {
  if (!bonusWallet.value) {
    return;
  }
  if (bonusWallet.value.linkedCategoryId) {
    void router.push({
      name: RouteName.PROMOTIONS,
      params: {
        categoryId: bonusWallet.value.linkedCategoryId,
      },
    });
  }
}

onMounted(async () => {
  await promotionsStore.getBonusWalletPromotionAction();
  if (!bonusWallet.value) {
    void router.push({ name: RouteName.PROMOTIONS });
  }
});
</script>

<template>
  <div v-auto-id="'BonusWalletRoutePage'" :class="$style['component-wrapper']">
    <div :class="$style['promotion promotion--bonus-wallet']">
      <template v-if="isReady && bonusWallet">
        <article :class="$style['promotion__article']">
          <div :class="$style['promotion__image-wrapper']">
            <img
              :class="$style['promotion__image']"
              :src="bonusWallet.bannerUrl ?? undefined"
              :alt="bonusWallet.shortDescription ?? undefined"
            >
          </div>

          <div :class="$style['promotion__padded-content']">
            <div :class="$style['promotion__brif-content']">
              <component
                :is="titleTag"
                :class="{
                  [$style['promotions-shared__title']]: true,
                  [$style['promotions-shared__title--big']]: true,
                  [$style['promotion__bonus-title-name']]: true,
                }"
              >
                {{ bonusWallet.name }}
              </component>

              <PromotionDetailsDescription
                v-if="!!bonusWallet.description"
                :description="bonusWallet.description"
                :class="$style['promotion__bonus-description']"
              />

              <VButton
                :class="{
                  [$style['promotion__button']]: true,
                  [$style['promotion__button--bonus-wallet']]: true,
                }"
                :full-width="!!null"
                :label="$t('WEB2_BONUS_ACTIONS')"
                @click="onBonusWalletButtonClick"
              />
            </div>

            <PromotionDetailsHowToReceiveBonus
              :class="$style['promotion__how-to-receive-bonus']"
              :title="bonusWallet.bonusTitle ?? undefined"
              :items="bonusWallet.bonusItems || []"
            />
          </div>

          <VHowToHintBlock
            :class="$style['promotion__how-to-hint']"
            background-image="bonus-wallet-how-to"
            :title="$t('WEB2_HOW_TO_USE_BONUSES')"
            :description="$t('WEB2_HOW_TO_USE_BONUSES_HINT')"
            :step-one="$t('WEB2_HOW_TO_USE_BONUSES_STEP1')"
            :step-two="$t('WEB2_HOW_TO_USE_BONUSES_STEP2')"
          />

          <VDynamicContent
            v-if="bonusWallet.howToUseBonusWidget"
            :class="$style['promotion__term-links']"
            :content="bonusWallet.howToUseBonusWidget"
          />
        </article>
      </template>
      <div
        v-if="!isReady"
        class="loader-masked"
      >
        <VLoaderDelayed />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import 'web/src/modules/promotions/styles/promotions-shared';
@import 'web/src/modules/promotions/styles/promotion';
</style>
