import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

import { RequirementValueType } from '@leon-hub/api-sdk';

import { useI18n } from '@core/i18n';
import { useFormatMoney } from '@core/money';

import type { VLinearProgressProps } from 'web/src/components/LinearProgress/VLinearProgress/types';
import getProgressProperties from 'web/src/components/TermsSteps/VTermsStepProgress/composables/utils/getProgressProperties';

import type { VTermsStepProgressProps } from '../types';

export interface VTermsStepProgressComposable {
  total: ComputedRef<string | Ref<string>>;
  progressProperties: Ref<VLinearProgressProps>;
}

export default function useVTermsStepProgress(props: VTermsStepProgressProps): VTermsStepProgressComposable {
  const { $translate } = useI18n();
  const $formatMoney = useFormatMoney();

  const progressProperties = computed(() => getProgressProperties(props));

  const betsTotalParams = computed(() => ({
    betsTotal: props.requiredValue ? props.requiredValue : '',
  }));

  const total = computed(() => {
    if (props.valueType === RequirementValueType.MONEY) {
      return $formatMoney(Number(props.requiredValue));
    }

    if (props.requiredValue) {
      return $translate('WEB2_BONUS_WIDGET_BETS_REMAINING_COUNT', betsTotalParams).value;
    }

    return '';
  });

  return {
    progressProperties,
    total,
  };
}
