<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import type { VHowToHintBlockProps } from './types';
import { useVHowToHintBlock } from './composables';

const props = withDefaults(defineProps<VHowToHintBlockProps>(), {
  title: '',
  description: '',
  stepOne: '',
  stepTwo: '',
  backgroundImage: '',
});

const {
  iconStepOneProps,
  iconStepTwoProps,
  iconArrowStepProps,
  styleProperties,
} = useVHowToHintBlock(props);
</script>

<template>
  <section v-auto-id="'VHowToHintBlock'"
    :class="$style['how-to-hint']"
    :style="styleProperties"
  >
    <div :class="$style['how-to-hint__info-block']">
      <h2 :class="$style['how-to-hint__heading']">
        {{ title }}
      </h2>
      <p :class="$style['how-to-hint__text']">
        {{ description }}
      </p>
      <div :class="$style['how-to-hint__steps']">
        <div :class="$style['how-to-hint__step']">
          <div :class="$style['how-to-hint__step-icon']">
            <VIcon v-bind="iconStepOneProps" />
          </div>
          <div :class="$style['how-to-hint__step-text']">
            {{ stepOne }}
          </div>
        </div>
        <div :class="$style['how-to-hint__step-sep']">
          <VIcon
            v-bind="iconArrowStepProps"
            :class="$style['how-to-hint__step-sep-icon']"
          />
        </div>
        <div :class="$style['how-to-hint__step']">
          <div :class="$style['how-to-hint__step-icon']">
            <VIcon v-bind="iconStepTwoProps" />
          </div>
          <div :class="$style['how-to-hint__step-text']">
            {{ stepTwo }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" module>
.how-to-hint {
  border-radius: 5px;

  &__info-block {
    padding: 16px 12px;
    background-color: var(--Layer1);
    border-radius: 5px;

    @include is-app-layout-phone {
      text-align: center;
    }

    @include is-app-layout-desktop {
      width: 343px;
      padding-right: 16px;
      padding-left: 16px;
      margin: 12px 16px;
    }
  }

  &__heading {
    @include medium\16\20\025;

    margin: 0 0 8px;
    color: var(--TextDefault);
  }

  &__text {
    @include regular\14\24\025;

    margin: 0 0 10px;
    color: var(--TextPrimary);
  }

  &__step {
    display: flex;
    align-items: center;
    text-align: left;

    &-text {
      @include regular\14\20\025;

      color: var(--TextDefault);
    }

    &-icon {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-right: 8px;
      color: var(--TextDefault);
      background-color: var(--Layer2);
      border-radius: 50%;
    }

    &-sep {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 36px;
      color: var(--TextPrimary);

      &-icon {
        fill: currentcolor;
      }
    }
  }

  @include is-app-layout-phone {
    padding: 0 8px;
    margin-top: -8px;
    margin-bottom: 16px;
  }

  @include is-app-layout-desktop {
    overflow: hidden;
  }
}
</style>
