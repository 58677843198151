import type { Ref } from 'vue';
import { computed } from 'vue';

import { DateTime, TimeFormats } from '@leon-hub/utils';

import { useFormatMoney } from '@core/money';

import type { PromotionCashbackHistoryProps } from 'web/src/modules/promotions/components/PromotionCashbackHistory/types';

interface PromotionCashbackHistoryComposable {
  formattedEndDate(date: string): string;
  isCurrentCashback(date: string): boolean;
  formattedItemCashbackAmount(amount: number): string;
  currentCashbackFormatted: Ref<string>;
}
export default function usePromotionCashbackHistory(
  props: PromotionCashbackHistoryProps,
): PromotionCashbackHistoryComposable {
  const formatMoney = useFormatMoney();

  const currentCashbackFormatted = computed(() => formatMoney(props.currentCashback));

  const formattedItemCashbackAmount = (amount: number) => formatMoney(amount);

  const formattedEndDate = (date: string) => {
    const timestamp = DateTime.toTimestamp(date);
    const format = TimeFormats.dayMonthShortYear;
    return DateTime.formatTimeStamp(timestamp, format);
  };

  const isCurrentCashback = (date: string) => {
    const currentDateTime = Date.now();
    const timestamp = DateTime.toTimestamp(date);
    return timestamp >= currentDateTime;
  };

  return {
    formattedEndDate,
    isCurrentCashback,
    formattedItemCashbackAmount,
    currentCashbackFormatted,
  };
}
