<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { BadgeKind, VBadge } from '@components/badge';
import { VImage } from '@components/image';
import { VIcon } from '@components/v-icon';

import type { PromotionsListItemProps } from 'web/src/modules/promotions/components/PromotionsListItem/types';
import PromotionCountdown from 'web/src/modules/promotions/components/PromotionCountdown/PromotionCountdown.vue';
import usePromotionsListItem
  from 'web/src/modules/promotions/components/PromotionsListItem/composables/usePromotionsListItem';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';

const props = defineProps<PromotionsListItemProps>();

const {
  metaParameters,
  showBadges,
  hasCounter,
  imageProps,
  detailsLocation,
  textPosition,
  likes,
  timestamp,
  promotion,
  isBeforeStart,
  isCustomerParticipatingCashback,
  sendRequest,
} = usePromotionsListItem(props);

useSeoMetaParameters(metaParameters);
</script>

<template>
  <router-link v-auto-id="'PromotionsListItem'"
    :class="$style['promotions__link']"
    :to="detailsLocation"
  >
    <article
      v-if="promotion"
      :class="$style['promotion-item']"
    >
      <div
        v-if="promotion.position"
        v-data-test="{ el: 'promotion-item-position' }"
        :class="$style['promotion-item__position']"
      >
        <VIcon
          :class="$style['promotion-item__position-icon']"
          :name="IconName.CUP"
          :size="IconSize.SIZE_16"
        />
        {{ promotion.position }}{{ textPosition }}
      </div>
      <figure
        :class="$style['promotion-item__image-wrapper']"
      >
        <VImage
          v-if="promotion.shortBannerImage"
          v-data-test="{ el: 'short-banner-image' }"
          v-bind="imageProps"
          :class="{
            [$style['promotion-item__image']]: true,
            [$style['promotion-item__image--archived']]: isArchived,
          }"
        />
        <img
          v-else
          v-data-test="{ el: 'banner-image' }"
          :class="{
            [$style['promotion-item__image']]: true,
            [$style['promotion-item__image--archived']]: isArchived,
          }"
          :src="promotion.shortBannerUrl ?? undefined"
          :alt="promotion.shortDescription ?? undefined"
        >
        <PromotionCountdown
          v-if="hasCounter"
          :class="$style['promotion-item__countdown']"
          :is-before-start="isBeforeStart"
          :timestamp="timestamp"
          :is-cashback="isCustomerParticipatingCashback"
          :is-alt-time-name="isCustomerParticipatingCashback"
          @send="sendRequest"
        />
        <div
          v-if="isPromotionsLikeEnabled && likes && likes > 0"
          v-data-test="{ el: 'promotion-item-likes' }"
          :class="$style['promotion-item__likes']"
        >
          <VIcon
            :name="IconName.LIKE"
            :size="IconSize.SIZE_16"
            :class="$style['promotion-item__likes-icon']"
          />
          <span :class="$style['promotion-item__likes-value']">
            {{ likes }}
          </span>
        </div>
      </figure>
      <div
        v-if="showBadges"
        v-data-test="{ el: 'promotion-badges' }"
        :class="$style['promotion-item__image-badges']"
      >
        <VIcon
          v-if="promotion.isHot"
          v-data-test="{ el: 'promotion-badges-hot' }"
          :class="$style['promotion-item__image-hot']"
          :size="IconSize.SIZE_16"
          :name="IconName.FIRE"
        />

        <VBadge
          v-if="promotion.isNew"
          v-data-test="{ el: 'promotion-badges-isnew' }"
          :class="$style['promotion-item__image-badge']"
          :kind="BadgeKind.WHITE"
          label="New"
        />
      </div>
      <VBadge
        v-if="promotion.isNT"
        v-data-test="{ el: 'promotion-badges-isnt' }"
        :class="$style['promotion-item__image-badge--dev-ip']"
        :kind="BadgeKind.WHITE"
        label="DevIP"
      />
      <div :class="$style['promotion-item__description']">
        <div :class="$style['promotion-item__title-wrapper']">
          <p :class="$style['promotion-item__title']">
            {{ promotion.name }}
          </p>
        </div>
        <span :class="$style['promotion-item__short-text']">
          {{ promotion.shortDescription }}
        </span>
      </div>
    </article>
  </router-link>
</template>

<style lang="scss" module>
// Watch comment in https://jira.leoncorp.net/browse/LEONWEB-1619
$imageAspectRatio: 2.4;

.promotion-item {
  $self: &;

  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: var(--Layer1);
  border-radius: if($isEnvFeatureTwinlikeStyle, 15px, 5px);
  mask-image: radial-gradient(white, black);

  &__countdown {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--OpacityLayer0);
  }

  &__title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;

    @include is-app-layout-desktop {
      flex-wrap: wrap;
    }
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    min-height: 76px;
    margin: 0;
    overflow: hidden;

    &:before {
      display: block;
      width: 100%;
      padding-top: calc((1 / #{$imageAspectRatio}) * 100%);
      content: '';
    }
    #{$self}__image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      backface-visibility: hidden;
      transition: transform 0.3s ease-in-out;

      &--archived {
        opacity: 0.5;
        filter: grayscale(100%);
      }
    }

    @include is-app-layout-desktop {
      width: 100%;
      height: auto;
      overflow: hidden;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        content: '';
      }
    }
  }

  &__image-badges {
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 5;
    display: inline-flex;
    column-gap: 2px;
    align-items: center;
    color: var(--DTextDefault);
  }

  .promotion-item__image-badge--dev-ip {
    @include z-index('above-base');

    position: absolute;
    top: 8px;
    left: 8px;
    height: 36px;
    font-size: 27px;
    line-height: 27px;
  }

  &__description {
    padding: 12px;

    @include is-app-layout-desktop {
      padding: 16px;
    }
  }

  &__title {
    @include medium\18\20;

    margin: 0;
    color: var(--TextDefault);
  }

  &__short-text {
    @include regular\13\20;

    color: var(--TextPrimary);
  }

  &__place {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  &__position {
    @include medium\13\16;

    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 103px;
    height: 32px;
    padding: 8px;
    color: var(--DTextDefault);
    background: var(--DBrandDefault);
    border: 1px solid var(--White);
    border-radius: 4px;

    &-icon {
      margin-right: 5px;
    }
  }

  &__likes {
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: flex;
    gap: 4px;
    padding: 8px;
    background-color: var(--BrandText);
    border-radius: 4px;

    &-icon {
      fill: var(--BrandDefault);
    }

    &-value {
      @include medium\13\16;

      color: var(--AlertButtonText);
    }
  }
}

.promotions__link {
  text-decoration: none;

  @include is-app-layout-desktop {
    &:hover {
      .promotion-item {
        &__image {
          transform: scale(1.2) rotate(-3deg);
        }

        &__image-wrapper:after {
          display: block;
        }

        &__title {
          color: var(--BrandHighlight);
        }
      }
    }
  }
}

@include for-browser-safari {
  .promotion-item {
    &__image-wrapper {
      z-index: z-index(base);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}
</style>
