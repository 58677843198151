import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';
import { useRoute } from 'vue-router';

import type { RefuseBonusResponse } from '@leon-hub/api-sdk';
import {
  BonusCampaignType,
  CategoryType,
  PromoActionType,
  RefuseBonusType,
} from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import { assert, isOptionalString } from '@leon-hub/guards';
import { AlertIconName } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { ShowDialogOptions } from '@core/dialogs';
import { useAnalytics } from '@core/analytics';
import { DialogAction, DialogComponent, PresetName, useDialogs, useDialogsStore } from '@core/dialogs';
import { useI18n } from '@core/i18n';
import { useFormatMoney } from '@core/money';
import { useNavigationStore } from '@core/navigation';

import type { VButtonProps } from '@components/buttons';
import { ButtonKind } from '@components/buttons';
import { ModalWidth } from '@components/dialogs';

import useFomoBonusNotification
  from 'web/src/modules/front-notifications/components/FomoBonusNotification/composables/useFomoBonusNotification';
import { getImageOrIcon } from 'web/src/modules/icons';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import { usePromotionsStore } from 'web/src/modules/promotions/store';

interface PromotionRefuseButtonComposable {
  refuseButtonProperties: Ref<VButtonProps>;
  isRefuseButtonAvailable: Ref<boolean>;
  onRefuseClick(): Promise<void>;
  refuseButtonLoading: Ref<boolean>;
}
export default function usePromotionRefuseButton(): PromotionRefuseButtonComposable {
  const refuseButtonLoading = ref(false);

  const { $translate } = useI18n();
  const promotionStore = usePromotionsStore();
  const route = useRoute();
  const dialogsStore = useDialogsStore();
  const profileBonusesStore = useProfileBonusesStore();
  const formatMoney = useFormatMoney();
  const { showDialog } = useDialogs();
  const analytics = useAnalytics();
  const navigationStore = useNavigationStore();
  const fomoBonusNotification = useFomoBonusNotification();

  const refuseButtonProperties = computed<VButtonProps>(() => ({
    label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
    kind: ButtonKind.SECONDARY,
    isLoading: refuseButtonLoading.value,
    fullWidth: true,
  }));

  const promotion = toRef(promotionStore, 'promotion');

  const bonusCampaignType = computed(() => {
    if (promotion.value.promotionType === PromoActionType.SBC) {
      return BonusCampaignType.SPORT;
    }
    if (promotion.value.promotionType === PromoActionType.CBC) {
      return BonusCampaignType.EGS;
    }
    return null;
  });

  const isRefuseButtonAvailable = computed(() => (
    !!promotion.value.sportBonus?.refuseAvailable && bonusCampaignType.value !== null
  ));

  const getRefuseTranslations = (refuseBonus: RefuseBonusResponse) => {
    const data = {
      title: $translate('WEB2_BCAMPAIGN_OPT_OUT_CONFIRMATION_TITLE').value,
      confirmMessage: '',
    };

    switch (refuseBonus.refuseType) {
      case RefuseBonusType.ONLY_WRITEOFF:
        data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_FREEBET_WRITEOFF', ref({
          amount: formatMoney(refuseBonus.writeOffAmount || 0),
        })).value;
        break;
      case RefuseBonusType.WRITEOFF_AND_DEPOSIT_RETURN:
        data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_WRITEOFF_AND_DEPOSIT_RETURN', ref({
          depositAmount: formatMoney(refuseBonus.returnDepositAmount || 0),
          writeOffAmount: formatMoney(refuseBonus.writeOffAmount || 0),
        })).value;
        break;
      case RefuseBonusType.FREEBET_CANCEL:
        data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_FREEBET_CANCEL', ref({
          amount: formatMoney(refuseBonus.freebetAmount || 0),
        })).value;
        break;
      case RefuseBonusType.FREESPIN_CANCEL:
        data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_FREESPIN_CANCEL', ref({
          amount: refuseBonus?.freespinCount?.toString() || '0',
        })).value;
        break;
      default:
        data.confirmMessage = $translate('WEB2_BCAMPAIGN_OPT_OUT_NONE').value;
        break;
    }

    return data;
  };

  const onRefuseClick = async () => {
    let modalRefuseLoading = false;
    let modalId = '';
    const { actionUrl } = route.params;
    const bonusId = promotion.value.sportBonus?.bonusId || null;

    const modalPreset = dialogsStore.dialogPresets.CONFIRM;
    const modalPresetButtons = modalPreset.buttons ?? [];

    const modalProperties: ShowDialogOptions = {
      presetName: PresetName.CONFIRM,
      options: {
        ...modalPreset,
        image: getImageOrIcon({
          alertIcon: AlertIconName.QuestionMark,
        }).image,
        modalComponent: DialogComponent.ConfirmModal,
        width: ModalWidth.SMALL,
        buttons: [
          {
            label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
            isLoading: modalRefuseLoading,
          },
        ],
      },
    };

    if (bonusId && bonusCampaignType.value !== null) {
      refuseButtonLoading.value = true;
      const campaignType = bonusCampaignType.value;

      try {
        const refuseBonus = await profileBonusesStore.refuseBonus({
          bonusId,
          campaignType,
          preview: true,
        });

        const { title, confirmMessage } = getRefuseTranslations(refuseBonus);

        modalProperties.options.title = title;
        modalProperties.options.confirmMessage = confirmMessage;

        const { id, subscribe } = showDialog(modalProperties);

        modalId = id;

        subscribe({
          [DialogAction.CONFIRM]: () => {
            analytics.push(AnalyticsEvent.Z_CAMPAIGN_REJECTION, {
              campaignRejection: true,
            });

            modalRefuseLoading = true;

            const newProps = {
              buttons: [
                {
                  label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
                  isLoading: modalRefuseLoading,
                },
                modalPresetButtons[1],
              ],
            };

            dialogsStore.updateDialogProps(modalId, newProps);

            void profileBonusesStore.refuseBonus({
              bonusId,
              campaignType,
              preview: false,
            })
              .then(async () => {
                assert(isOptionalString(actionUrl));
                fomoBonusNotification.checkAndHideNotification(bonusId);
                await Promise.all([
                  navigationStore.fetchPromotionsNavigationConfig(),
                  promotionStore.getPromotionsByCategoryType(CategoryType.CUSTOMER),
                  promotionStore.loadPromotionDetailsWithPolling({ actionUrl, initial: true }),
                ]);
              })
              .finally(() => {
                refuseButtonLoading.value = false;
                modalRefuseLoading = false;
              });
          },
          [DialogAction.MODAL_CLOSE]: () => {
            refuseButtonLoading.value = false;
            modalRefuseLoading = false;
          },
        });
      } catch (rawError) {
        const error = normalizeError(rawError);
        refuseButtonLoading.value = false;
        showDialog({
          presetName: PresetName.ALERT_WARNING,
          options: {
            title: $translate('WEB2_BONUSES_REFUSE_FAILED_TITLE').value,
            confirmMessage: error.message,
            dataTestId: 'promo-bonuses-refuse-failed',
          },
        });
      }
    }
  };

  return {
    isRefuseButtonAvailable,
    refuseButtonProperties,
    refuseButtonLoading,
    onRefuseClick,
  };
}
