import type { VHowToHintBlockProps } from 'web/src/components/HowToHintBlock/VHowToHintBlock/types';

export default function getStylesProperties(props: VHowToHintBlockProps): Dictionary<string> {
  if (process.env.VUE_APP_LAYOUT_DESKTOP && props.backgroundImage) {
    return {

      background: `url(${require(`web/src/assets/images/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/${props.backgroundImage}.png`)}) 100%/cover no-repeat`,
    };
  }

  return {};
}
