import type { ComputedRef } from 'vue';
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { useNavigationItemsStore } from '@core/navigation';

import type { SidebarMenu } from 'web/src/components/SidebarMenu/types';
import { usePromotionsStore } from 'web/src/modules/promotions/store';

import {
  getIsPromoMainPage,
  getMenu,
} from './utils';

export interface PromotionsSidebarRouteComponentComposable {
  menu: ComputedRef<SidebarMenu>;
  isPromoMainPage: ComputedRef<boolean>;
}

export default function usePromotionsSidebarRouteComponent(): PromotionsSidebarRouteComponentComposable {
  const router = useRouter();
  const promotionCategoriesNavigationItems = toRef(useNavigationItemsStore(), 'promotionCategoriesNavigationItems');
  const participationNumber = toRef(usePromotionsStore(), 'participationNumber');

  const isPromoMainPage = computed<boolean>(() => getIsPromoMainPage(
    router.currentRoute.value.name,
    router.currentRoute.value.params.categoryId,
  ));
  const menu = computed<SidebarMenu>(() => getMenu(
    promotionCategoriesNavigationItems.value,
    router,
    router.currentRoute.value.name,
    router.currentRoute.value.params.actionUrl,
    router.currentRoute.value.params.categoryId,
    participationNumber.value,
  ));

  return {
    menu,
    isPromoMainPage,
  };
}
