<script setup lang="ts">
import type { CmsContentProps } from 'web/src/modules/cms/components/types';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';

const props = withDefaults(defineProps<CmsContentProps>(), {
  spintaxCms: false,
});
</script>

<template>
  <div v-auto-id="'PromotionsTermsModalPage'" :class="$style['promotions-terms-padding']">
    <VCmsContent v-bind="props" />
  </div>
</template>

<style module lang="scss">
.promotions-terms-padding {
  padding: 0 16px 32px;
}
</style>
