import type { RouteLocationRaw } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';

import type { AppVueRouter } from '@core/router';

import { PromotionItemType } from 'web/src/modules/promotions/types';

import resolveCategoryLink from './resolveCategoryLink';
import resolvePromotionLink from './resolvePromotionLink';

export default function resolveSubCategoryLink(
  item: NavigationItem,
  router: AppVueRouter,
): RouteLocationRaw {
  if (item.type === PromotionItemType.PROMOTION) {
    return resolvePromotionLink(item, router);
  }
  return resolveCategoryLink(item, router);
}
