import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  onActivated,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { CategoryType } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';
import { onComponentDeactivated } from '@leon-hub/vue-utils';

import { useIsLoggedIn } from '@core/auth';
import { useI18n } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';

import type { VButtonProps } from '@components/buttons';
import { ButtonKind } from '@components/buttons';

import type {
  PromotionsByCategoryType,
} from 'web/src/modules/promotions/types';
import { usePromotionsRoute } from 'web/src/modules/promotions/pages/composables';
import { usePromotionsStore } from 'web/src/modules/promotions/store';

export interface PromotionsRoutePageComposable {
  promotionIsReady: Ref<boolean>;
  actionCaption: ComputedRef<string>;
  hasPromotions: ComputedRef<boolean>;
  isMyActions: Ref<boolean>;
  getActivePromotions: Ref<string[]>;
  isArchivedCategory: ComputedRef<boolean>;
  isPromotionsLikeEnabled: Ref<boolean>;
  isCustomerCategory: ComputedRef<boolean>;
  filteredPromotionsByCategory: ComputedRef<PromotionsByCategoryType[]>;
  finishedPromotions: Ref<string[]>;
  buttonProperties: ComputedRef<VButtonProps>;
  onListEmptyClick(): void;
  promotionsLoaded: Ref<boolean>;
  categoryId: ComputedRef<string | string[]>;
  sendYM(): void;
  navigationItem: ComputedRef<NavigationItem | null>;
}

export default function usePromotionsRoutePage(): PromotionsRoutePageComposable {
  const router = useRouter();
  const { $translate } = useI18n();
  const { isLoggedIn } = useIsLoggedIn();

  const promotionsStore = usePromotionsStore();
  const siteConfigStore = useSiteConfigStore();

  const {
    categoryId,
    isArchivedCategory,
    navigationItem,
    sendYM,
  } = usePromotionsRoute();

  const promotionIsReady = toRef(promotionsStore, 'promotionIsReady');
  const isMyActions = toRef(promotionsStore, 'isMyActions');
  const getActivePromotions = toRef(promotionsStore, 'getActivePromotions');
  const finishedPromotions = toRef(promotionsStore, 'finishedPromotions');
  const promotionsLoaded = toRef(promotionsStore, 'promotionsLoaded');
  const promotionsByCategory = toRef(promotionsStore, 'promotionsByCategory');
  const customerCategoryFromPromotionStore = toRef(promotionsStore, 'customerCategory');
  const isPromotionsLikeEnabled = toRef(siteConfigStore, 'isPromotionsLikeEnabled');

  const isPageActive = ref<boolean>(false);

  const filteredPromotionsByCategory = computed<PromotionsByCategoryType[]>(() => promotionsByCategory.value.filter((item) => item.value.length > 0));

  const buttonProperties = computed<VButtonProps>(() => ({
    label: $translate('WEB2_PROMOTIONS_ARCHIVE').value,
    kind: ButtonKind.BASE,
    rounded: true,
  }));

  const hasPromotions = computed<boolean>(() => getActivePromotions.value.length > 0);

  const archivedCategory = promotionsStore.categoryIdByCategoryType(CategoryType.ARCHIVED);

  const customerCategory = promotionsStore.categoryIdByCategoryType(CategoryType.CUSTOMER);

  const isCustomerCategory = computed<boolean>(() => categoryId.value === customerCategory.value);

  const actionCaption = computed<string>(() => navigationItem.value?.caption || '');

  const updatePromotions = () => {
    if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      void promotionsStore.getPromotionsByCategoryId(navigationItem.value?.id || '')
        .finally(() => {
          if (categoryId.value && !navigationItem.value?.id && !promotionsStore.promotion?.isArchived) {
            void router.replace({ name: RouteName.PROMOTIONS });
          }
        });
    }
  };

  onMounted(() => {
    isPageActive.value = true;

    if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && customerCategoryFromPromotionStore.value !== null) {
      void promotionsStore.fetchPromotions(customerCategoryFromPromotionStore.value);
      void promotionsStore.getPromotionsByCategoryType(CategoryType.CUSTOMER_ARCHIVED);
    }

    updatePromotions();
  });

  onActivated(() => {
    isPageActive.value = true;
  });

  onComponentDeactivated(() => {
    isPageActive.value = false;
  });

  const onListEmptyClick = () => {
    void router.push({
      name: RouteName.PROMOTIONS,
      params: { categoryId: archivedCategory.value },
    });
  };

  watch(isLoggedIn, () => {
    updatePromotions();
  });

  watch(isPageActive, (newValue) => {
    if (newValue) {
      updatePromotions();
      sendYM();
    }
  });

  watch(categoryId, () => {
    if (isPageActive.value) {
      updatePromotions();
      sendYM();
    }
  }, { immediate: true, deep: true });

  return {
    promotionIsReady,
    actionCaption,
    hasPromotions,
    isMyActions,
    getActivePromotions,
    isArchivedCategory,
    isPromotionsLikeEnabled,
    isCustomerCategory,
    filteredPromotionsByCategory,
    finishedPromotions,
    buttonProperties,
    onListEmptyClick,
    promotionsLoaded,
    categoryId,
    sendYM,
    navigationItem,
  };
}
