<script setup lang="ts">
import { ref } from 'vue';

import type { PromotionTermsProps } from 'web/src/modules/promotions/components/PromotionTerms/types';
import VTermsSteps from 'web/src/components/TermsSteps/VTermsSteps/VTermsSteps.vue';
import usePromotionTerms from 'web/src/modules/promotions/components/PromotionTerms/composables/usePromotionTerms';

const props = defineProps<PromotionTermsProps>();
const {
  requirementsData,
  showTerms,
  onReadMoreClick,
} = usePromotionTerms(props);

const terms = ref<Optional<HTMLDivElement>>();

defineExpose({
  getOffsetTop: () => terms.value?.offsetTop || 0,
  scrollIntoView: () => terms.value?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  }),
});
</script>

<template>
  <div v-auto-id="'PromotionTerms'"
    v-if="showTerms"
    ref="terms"
    :class="$style['promotion-terms']"
  >
    <h2
      :class="{
        [$style['promotions-shared__title']]: true,
        [$style['promotion-terms__title']]: true,
      }"
    >
      {{ requirementsData.title }}
    </h2>

    <VTermsSteps
      :class="$style['promotion-terms__steps']"
      :items="requirementsData.items"
      :show-read-more-button="!!termsKey"
      @click="onReadMoreClick"
    />
  </div>
</template>

<style lang="scss" module>
@import 'web/src/modules/promotions/styles/promotions-shared';

.promotion-terms {
  &__title {
    margin-bottom: 16px;
  }

  &__progress {
    margin-bottom: 16px;

    @include is-app-layout-desktop {
      margin-bottom: 20px;
    }
  }
}
</style>
