<script lang="ts" setup>
import { VButton } from '@components/buttons';

import { VLoaderDelayed } from 'web/src/components/Loader';
import PromotionsList from 'web/src/modules/promotions/components/PromotionsList/PromotionsList.vue';
import PromotionsListEmpty from 'web/src/modules/promotions/components/PromotionsListEmpty/PromotionsListEmpty.vue';

import { usePromotionsRoutePage } from './composables';

const {
  promotionIsReady,
  actionCaption,
  hasPromotions,
  isMyActions,
  getActivePromotions,
  isArchivedCategory,
  isPromotionsLikeEnabled,
  isCustomerCategory,
  filteredPromotionsByCategory,
  finishedPromotions,
  buttonProperties,
  onListEmptyClick,
  promotionsLoaded,
  categoryId,
} = usePromotionsRoutePage();
</script>

<template>
  <div v-auto-id="'PromotionsRoutePage'" :class="$style['component-wrapper']">
    <div :class="$style['promotions']">
      <template v-if="categoryId">
        <div
          v-if="promotionIsReady"
          :class="$style['promotions__section-wrapper']"
        >
          <p
            v-if="actionCaption && hasPromotions && isMyActions"
            :class="[
              $style['promotions-shared__title'],
              $style['promotions-shared__title--left'],
              $style['promotions__title'],
            ]"
          >
            {{ !isMyActions ? actionCaption : $t('WEB2_ACTIVE') }}
          </p>
          <template v-if="isArchivedCategory">
            <PromotionsList
              v-if="hasPromotions"
              :promotions="getActivePromotions"
              :is-promotions-like-enabled="isPromotionsLikeEnabled"
              is-archived
              :class="$style['promotions__list']"
            />
            <PromotionsListEmpty
              v-else
              :title="$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND')"
              :description="$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND_DESC')"
              :class="$style['promotions__list-empty']"
            />
          </template>
          <template v-else-if="filteredPromotionsByCategory.length">
            <template v-for="item in filteredPromotionsByCategory">
              <div
                v-if="!!item.value.length"
                :key="item.id"
              >
                <p
                  v-if="!isCustomerCategory && ('1' || item.hasCategory)"
                  :class="[
                    $style['promotions-shared__title'],
                    $style['promotions-shared__title--left'],
                    $style['promotions__title'],
                  ]"
                >
                  {{ item.name }}
                </p>
                <PromotionsList
                  :promotions="item.value"
                  :is-promotions-like-enabled="isPromotionsLikeEnabled"
                  :is-archived="false"
                  :class="$style['promotions__list']"
                />
              </div>
            </template>
          </template>
          <PromotionsListEmpty
            v-else
            :title="$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND')"
            :description="$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND_DESC')"
            :class="$style['promotions__list-empty']"
          >
            <template #button>
              <VButton
                v-if="!isArchivedCategory"
                v-bind="buttonProperties"
                :class="$style['promotions__button']"
                @click="onListEmptyClick"
              />
            </template>
          </PromotionsListEmpty>
        </div>
        <div
          v-if="promotionIsReady"
          :class="$style['promotions__section-wrapper']"
        >
          <p
            v-if="isMyActions && finishedPromotions.length"
            :class="[
              $style['promotions-shared__title'],
              $style['promotions-shared__title--left'],
              $style['promotions__title'],
            ]"
          >
            {{ $t('WEB2_FINISHED_PROMOTIONS') }}
          </p>
          <PromotionsList
            v-if="isMyActions && finishedPromotions.length"
            :promotions="finishedPromotions"
            :is-promotions-like-enabled="isPromotionsLikeEnabled"
            is-archived
            :class="$style['promotion__list']"
          />
        </div>
        <div
          v-if="!promotionIsReady"
          :class="$style['loader-masked']"
        >
          <VLoaderDelayed />
        </div>
      </template>
      <template v-if="!categoryId">
        <div
          v-if="!promotionsLoaded"
          :class="$style['loader-masked']"
        >
          <VLoaderDelayed />
        </div>
        <template v-else>
          <template v-if="filteredPromotionsByCategory.length">
            <template v-for="item in filteredPromotionsByCategory">
              <div
                v-if="!!item.value.length"
                :key="item.id"
                :class="$style['promotions__section-wrapper']"
              >
                <p
                  :class="[
                    $style['promotions-shared__title'],
                    $style['promotions-shared__title--left'],
                    $style['promotions__title'],
                  ]"
                >
                  {{ item.name }}
                </p>
                <PromotionsList
                  :promotions="item.value"
                  :is-promotions-like-enabled="isPromotionsLikeEnabled"
                  :is-archived="false"
                  :class="$style['promotions__list']"
                />
              </div>
            </template>
          </template>
          <PromotionsListEmpty
            v-else
            :title="$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND')"
            :description="$t('WEB2_PROMOTIONS_AND_BONUSES_NOT_FOUND_DESC')"
            :class="$style['promotions__list-empty']"
          >
            <template #button>
              <VButton
                v-if="!isArchivedCategory"
                v-bind="buttonProperties"
                :class="$style['promotions__button']"
                @click="onListEmptyClick"
              />
            </template>
          </PromotionsListEmpty>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
@import 'web/src/modules/promotions/styles/promotions-shared';

.promotions {
  position: relative;
  padding: 8px 0;

  &__section-wrapper {
    @include is-app-layout-phone {
      padding: 8px;
    }

    @include is-app-layout-desktop {
      padding: 0 0 8px;
    }
  }

  &__title {
    padding: 16px 0;

    @include is-app-layout-phone {
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  &__preloader {
    margin: 20px;
  }

  &__button {
    margin: 0 auto;
    text-transform: none;
  }

  &__list-empty {
    margin-top: 40px;
    margin-bottom: 40px;

    @include is-app-layout-phone {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}

.component-wrapper {
  min-height: calc(100vh - 130px);
  background-color: var(--Layer0);

  @include is-app-layout-desktop {
    min-height: calc(100vh - 56px);
  }
}

.loader-masked {
  @include z-index('online-chat');

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 150px;
}
</style>
