<script lang="ts" setup>
import { RequirementValueType } from '@leon-hub/api-sdk';

import { ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';

import type { VTermsStepProgressProps } from './types';
import { useVTermsStepProgress } from './composables';

const props = withDefaults(defineProps<VTermsStepProgressProps>(), {
  progressBarValue: 0,
  valueType: RequirementValueType.COUNT,
  kind: ProgressKind.DEFAULT,
  currentValue: '0',
  requiredValue: '0',
});

const {
  progressProperties,
  total,
} = useVTermsStepProgress(props);
</script>

<template>
  <div v-auto-id="'VTermsStepProgress'" :class="$style['terms-step-progress']">
    <VLinearProgress
      :class="$style['terms-step-progress__progress']"
      v-bind="progressProperties"
    />
    <div :class="$style['terms-step-progress__footer']">
      <div :class="$style['terms-step-progress__percent']">
        {{ progressBarValue }} %
      </div>
      <div>
        <span :class="$style['terms-step-progress__current']">{{ currentValue }}</span>
        <span :class="$style['terms-step-progress__separator']">/</span>
        <span :class="$style['terms-step-progress__total']">{{ total }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.terms-step-progress {
  padding: 8px 16px;

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }

  &__percent {
    @include medium\12\12;

    color: var(--BrandDefault);
  }

  &__current {
    @include medium\13\20;

    color: var(--TextDefault);
  }

  &__separator {
    @include medium\13\20;

    margin: 0 4px;
    color: var(--TextSecondary);
  }

  &__total {
    @include medium\13\20;

    color: var(--TextSecondary);
  }
}
</style>
