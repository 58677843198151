import type { NavigationItem } from '@leon-hub/navigation-config';
import { CategoryType } from '@leon-hub/api-sdk';

import type { VBadgeProps } from '@components/badge';

export default function mapBadges(
  item: NavigationItem,
  participationNumber: number,
): VBadgeProps['label'] {
  if (item.props?.type === CategoryType.CUSTOMER) {
    return String(participationNumber || '');
  }
  return item.badge;
}
