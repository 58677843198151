import type {
  defineComponent,
} from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VListItem, VListItemAccordion } from '@components/list';

import type { VTermsStepsProps } from '../types';

export interface VTermsStepsComposable {
  iconCheckProps: VIconProps;
  iconMoreProps: VIconProps;
  listItemControl(index: number): VListItemControl;
}

type VListItemControl = ReturnType<typeof defineComponent>;
export default function useVTermsSteps(props: VTermsStepsProps): VTermsStepsComposable {
  const iconCheckProps: VIconProps = {
    name: IconName.CHECK_FILLED,
    size: IconSize.SIZE_20,
  };
  const iconMoreProps: VIconProps = {
    name: IconName.OPEN_IN_NEW,
    size: IconSize.SIZE_16,
  };

  function listItemControl(index: number): VListItemControl {
    if (props.items) {
      const { currentValue, requiredValue } = props.items[index];
      const isAccordion = currentValue !== null && requiredValue !== null;

      if (isAccordion) {
        return VListItemAccordion;
      }
    }

    return VListItem;
  }

  return {
    iconCheckProps,
    iconMoreProps,
    listItemControl,
  };
}
